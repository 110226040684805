body {
  background-color: #F3F3F3;
  height: 80vh;
}
.App {
  font-family: Inter;
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 80vh;
  margin: 6%;
}
.App > img {
  margin-top: 3%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  width: 100px;
  margin-bottom: 2%;
}
.userform {
  display: flex;
}
.form {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.userForm-container {
  width: 62%;
  margin: auto;
  margin-top: 20px;
  padding: 50px;
  background-color: white;
  border-radius: 20px;
  /* box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 2px 0px #1976d2;
}
.userForm-container-last-step {
  width: 50%;
  margin: auto;
  margin-top: 20px;
  padding: 50px;
  border-radius: 10px;
}
label {
  text-align: left;
  font-size: smaller;
}
input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.InputField {
  /* width: 50%; */
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.userForm-container-footer {
  display: flex;
  margin: auto;
  width: 100%;
  margin-top: 20px;
}
button {
  background-color: #0D5BA6;
  color: white;
  padding: 14px 34px;
  margin: 8px 10px;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  font-weight: 700;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2)
}
span {
  font-weight: lighter;
  font-size: smaller;
}
.card-container {
  display: flex;
  justify-content: space-between;
}

.card-input-element {
  display: none;
}

.card-input {
  margin: 10px;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 5%;
  justify-content: space-between;
  margin: 5px;
}

.card-input:hover {
  cursor: pointer;
}
.card-input-element:checked + .card-input {
  box-shadow: 0 0 1px 1px #664de5;
}
.panel-heading {
  font-size: 15px;
}
.panel-body {
  font-size: 12px;
  font-weight: lighter;
}
.classofcheckicon{
  color: "#fff";
   width: 15;
   margin-top: 30;
   margin-bottom: 10;
   
}
.numbersofprogressbar{
  margin-top: 30;
  margin-bottom: 10;

}
.textfieldsclassmain{
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 20px;
}
.divclassoftextfields{
  display: flex;
  flex-direction: column;
  flex: 1;
}
.divclassoftextfields2{
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 30px;
}
.addclassforuserform-cont-head{
display: block;
flex-direction: row; 
margin-bottom: 20px;
}
.laststepcardsmainclass{
  display: flex;
  align-items: center;
  justify-content: center;
}
.laststepcardsmainclassofbox{
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 4%;
}
.cardsoflaststeps{
  flex: 1;
   margin: 5;
  border-radius: 16;
  box-shadow:"0px 2px 0px #1976d2"
}
.cardbottomclasss{
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.classforcheckicon{
  color: #fff; 
  width: 15; 
  margin-top: 20px; 
  margin-bottom: 10;
}
@media (max-width: 600px) {
  .flexContainer {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .textfieldsclassmain{
    display: block;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 20px;
    
  }
  .divclassoftextfields{
    display: block;
    flex-direction: column;
    flex: 1;
    margin-bottom: 20px;
  }
  .divclassoftextfields2{
    display: block;
    flex-direction: column;
    flex: 1;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .laststepcardsmainclass{
    display: block;
    align-items: center;
    justify-content: center;
  }
  .laststepcardsmainclassofbox{
    display: block;
    flex-direction: row;
    flex: 1;
  
  }
  .cardbottomclasss{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

}
.your-element {
  display: block; /* By default, it's visible on all screen sizes */
}

@media (max-width: 768px) {
  /* On screens with a maximum width of 768px (typical small screens like mobile devices) */
  .your-element {
    display: none; /* Hide the element on smaller screens */
  }
}
