.sliderDiv{
    margin-top: 20px;
    margin-bottom: 10px;
}

.slider{
    color:blue;
}

.booleanDiv{
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}
.step4SecondDiv{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
.step4InnerDiv{
    flex: 1;
}
@media (max-width: 600px) {
    .step4SecondDiv{
        display: block;
        flex-direction: row;
        margin-bottom: 20px;
    }
    
  }